/**
 * Import React and ReactDOM to expose for CMS pages.
 * Check client webpack config for expose-loader usage.
 * CMS content sometimes inlines <script> tags with React code to create client-side components.
 * Example page: https://www.shop-apotheke.com/lp/gutscheine/
 */
import 'react'
import 'react-dom'
import ctaButtonOnAllCSW from 'shared/experiments/browser/ctaButtonOnAllCSW/eventsTracking'
import serpNewSortByOptionEventsTracking from 'shared/experiments/browser/serpNewSortByOption/eventsTracking'
import { subscribe } from 'shared/store'
import { SEARCH_RESULTS_HITS_UPDATED } from 'shared/store/ducks/events'
import accordionAnchor from 'views/assets/scripts/accordionAnchor'
import brandshopsNavigation from 'views/assets/scripts/brandshopsNavigation'
import chargeUrlSearchParams from 'views/assets/scripts/chargeUrlSearchParams'
import dismiss from 'views/assets/scripts/dismiss'
import medicationPlan from 'views/assets/scripts/medicationPlan'
import mountSellerModalForCrossSell from 'views/assets/scripts/mountSellerModalForCrossSell'
import registerSliderStretcher from 'views/assets/scripts/registerSliderStretcher'
import runScripts from 'views/assets/scripts/runScripts'
import searchResultsHandlers from 'views/assets/scripts/searchResultsHandlers'
import { initSlider as slider } from 'views/assets/scripts/slider'
import tabs from 'views/assets/scripts/tabs'
import textAndImageWidth from 'views/assets/scripts/textAndImageWidth'
import video from 'views/assets/scripts/video'
import videoBox from 'views/assets/scripts/videoBox'
import voucherCode from 'views/assets/scripts/voucherCode'
import mountAlgoliaSearchComponent from 'views/containers/browser/AlgoliaInstantSearchContainer/mountAlgoliaSearchComponent'
import mountLastActivityContainer from 'views/containers/browser/LastActivityContainer/mountLastActivityContainer'
import mountMoreProductsContainer from 'views/containers/browser/MoreProductsContainer/mountMoreProductsContainer'
import mountNowFaqModalContainer from 'views/containers/browser/NowFaqModalContainer/mountNowFaqModalContainer'
import mountNowShippingModalContainer from 'views/containers/browser/NowShippingModalContainer/mountNowShippingModalContainer'
import mountSellerModalContainer from 'views/containers/browser/SellerModalContainer/mountSellerModalContainer'

const handleSellerModalContainer = () => {
  /**
   * Do not reload mountSellerModalContainer on first only
   * after clicking on pagination links on category search results page
   */
  let isSearchPageLoaded = false
  /**
   * We need to recall mountSellerModalContainer function as when we click on pagination link
   * on category search results page, Seller Modal is not loading contents due to the bug where click
   * handler is not registering dynamically added links for seller modal.
   */
  subscribe.after(SEARCH_RESULTS_HITS_UPDATED, () => {
    if (isSearchPageLoaded) {
      /**
       * we call it here again, so we can reattach click handler on seller links after
       * they are dynamically loaded on click on pagination links.
       */
      mountSellerModalContainer()
    }
    isSearchPageLoaded = true
  })
}

runScripts(
  accordionAnchor,
  ctaButtonOnAllCSW,
  tabs,
  slider,
  registerSliderStretcher,
  chargeUrlSearchParams,
  video,
  videoBox,
  mountLastActivityContainer,
  mountAlgoliaSearchComponent,
  mountNowShippingModalContainer,
  mountNowFaqModalContainer,
  mountMoreProductsContainer,
  dismiss,
  medicationPlan,
  textAndImageWidth,
  voucherCode,
  mountSellerModalForCrossSell,
  brandshopsNavigation,
  serpNewSortByOptionEventsTracking,
  handleSellerModalContainer,
  searchResultsHandlers
)
