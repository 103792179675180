import createA11Dialog from 'views/utils/createA11Dialog'

import { VIDEO_SELECTOR } from './MediaGallery/consts'

export default () => {
  const videoBoxButtons = [
    ...document.querySelectorAll('[data-clientside-hook~="videoBox__button"]'),
  ]
  const bodyElement = document.body
  const layoutElement = document.getElementById('layout') || bodyElement

  return videoBoxButtons.map(videoBoxButton => {
    const modalElement = document.getElementById(
      videoBoxButton.dataset.a11yDialogShow
    )
    const modal = createA11Dialog({
      element: modalElement,
      container: layoutElement,
    })
    const video = modalElement.querySelector(VIDEO_SELECTOR)

    if (video) {
      modal.on('show', () => {
        video.play()
      })

      modal.on('hide', () => {
        video.pause()
        video.load()
      })
    }

    return videoBoxButton
  })
}
