export default () => {
  const allVoucherCodeDivs = document.querySelectorAll('[data-voucher-code]')
  if (allVoucherCodeDivs.length > 0) {
    allVoucherCodeDivs.forEach(elem => {
      let voucherCode = elem.getAttribute('data-voucher-code')
      let inputWithVoucherCode = document.getElementById(
        'voucher_' + voucherCode
      )

      if (inputWithVoucherCode) {
        let voucherCodeComponent = inputWithVoucherCode.closest(
          '.o-CmsContent__ComponentVoucherCode'
        )
        elem.append(voucherCodeComponent)
      }
    })
  }
}
