import { BREAKPOINT_MEDIUM } from 'views/consts'

export default () => {
  const modulesWithWidth = document.querySelectorAll('[data-image-width]')
  const isLargeViewport = () => window.matchMedia(BREAKPOINT_MEDIUM).matches

  if (isLargeViewport()) {
    modulesWithWidth.forEach(item => {
      if (parseInt(item.dataset.imageWidth) > 0) {
        item.style.width = item.dataset.imageWidth
      }
    })
  }
}
