import { DEVELOPMENT, PRODUCTION, QS } from 'shared/consts/coreShopEnvironments'
import { TENANT_COM } from 'shared/consts/tenants'
import store, { subscribe } from 'shared/store'
import { actionNames } from 'shared/store/ducks/userSession'
import {
  MEDICATION_PLAN_CONTAINER_ID,
  MEDICATION_PLAN_DEV_URL,
  MEDICATION_PLAN_PROD_URL,
  MEDICATION_PLAN_QS_URL,
} from 'views/consts'
import createA11Dialog from 'views/utils/createA11Dialog'

import { ARIA_BUSY } from './consts'

const MEDICATION_PLAN_SELECTOR = '[data-clientside-hook~="medicationPlan"]'
const MEDICATION_PLAN_LOGGED_OUT_SELECTOR =
  '[data-clientside-hook~="medicationPlanLoggedOut"]'
const MODAL_SELECTOR = '[data-clientside-hook~="modal"]'

const SCRIPT_URL_MAP = {
  [DEVELOPMENT]: `${MEDICATION_PLAN_DEV_URL}/med.js`,
  [QS]: `${MEDICATION_PLAN_QS_URL}/med.js`,
  [PRODUCTION]: `${MEDICATION_PLAN_PROD_URL}/med.js`,
}

const getScriptUrl = env => SCRIPT_URL_MAP?.[env]

const setMedApi = (shopUrl, user) => {
  const {
    pageProperties: { coreShopEnvironment },
    publicConfig: {
      shopName,
      subscriptionServiceAPIURL: subscriptionApiUrl = '',
    },
  } = store.getPublicRuntimeConfig()

  const MedAPI = window?.MedAPI

  if (!MedAPI) {
    return
  }

  const {
    customerNumber,
    salutation: { code: salutation },
    firstName,
    lastName,
    email,
    dateOfBirth,
  } = user

  MedAPI.setShop(shopName, coreShopEnvironment)
  MedAPI.setEndpoint(`${shopUrl}${subscriptionApiUrl}`)
  MedAPI.setCustomer({
    customerNumber,
    salutation,
    firstName,
    lastName,
    email,
    dateOfBirth,
  })
  MedAPI.init(MEDICATION_PLAN_CONTAINER_ID)
}

const onContainerLoaded = () => {
  const container = document.querySelector(MEDICATION_PLAN_SELECTOR)

  if (container) {
    container.removeAttribute(ARIA_BUSY)
  }
}

const showLogInContent = () => {
  const container = document.getElementById(MEDICATION_PLAN_CONTAINER_ID)

  if (container) {
    container.hidden = false
  }
}

const showLogOutContent = () => {
  const container = document.querySelector(MEDICATION_PLAN_LOGGED_OUT_SELECTOR)

  if (container) {
    container.hidden = false
    const modal = createA11Dialog({
      element: container.querySelector(MODAL_SELECTOR),
    })

    modal.show()
  }
}

const loadScript = ({ shopUrl, scriptUrl, user }) => {
  const {
    pageProperties: { isStorybook = false },
  } = store.getPublicRuntimeConfig()
  const id = scriptUrl.replace(/[^0-9a-z\-_]+/gi, '-').trim('-')

  if (!document.getElementById(id)) {
    const script = document.createElement('script')

    script.id = id
    script.src = scriptUrl
    script.async = true
    script.onload = () => {
      setMedApi(shopUrl, user)
      onContainerLoaded()
    }

    document.getElementsByTagName('head')[0].appendChild(script)
  } else if (isStorybook) {
    window.location.reload()
  }
}

export default (props = {}) => {
  let { shopUrl = null, scriptUrl = null } = props
  const {
    pageProperties: { coreShopEnvironment },
    publicConfig: { tenant },
  } = store.getPublicRuntimeConfig()

  if (
    !document.querySelector(MEDICATION_PLAN_SELECTOR) ||
    tenant !== TENANT_COM
  ) {
    onContainerLoaded()
    return
  }

  subscribe.after.once(actionNames.setUserSession, ({ userSession }) => {
    const { isLoggedIn, user } = userSession

    if (!isLoggedIn) {
      showLogOutContent()
      onContainerLoaded()
      return
    }

    showLogInContent()

    if (!shopUrl) {
      const { protocol, hostname } = window.location
      shopUrl = `${protocol}//${hostname}`
    }

    if (!scriptUrl) {
      scriptUrl = getScriptUrl(coreShopEnvironment)
    }

    loadScript({ shopUrl, scriptUrl, user })
  })
}
