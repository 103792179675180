import store from 'shared/store'
import { isMobile } from 'shared/utils/deviceClassUtils'

const openAccordionItem = (accordion: Element | null) => {
  const accordionButton: HTMLElement | null | undefined =
    accordion?.querySelector('.m-Button--collapsible')
  accordionButton?.click()
}

export default () => {
  if (window.location.hash) {
    const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
    const { deviceClass } = publicRuntimeConfig?.publicConfig || {}

    const anchor = document.querySelector(
      `[data-id="${window.location.hash.replace('#', '')}"]`
    )

    if (anchor) {
      const accordion = anchor.closest('.m-Collapsible')
      const currentAccordion = accordion?.closest(
        '.o-CmsContent__ComponentAccordion'
      )
      const parentAccordion = currentAccordion?.closest('.m-Collapsible')

      if (parentAccordion) {
        openAccordionItem(parentAccordion)
      }

      if (!isMobile(deviceClass)) {
        openAccordionItem(accordion)
      }
    }
  }
}
