import { CTX_RULE_PRODUCTS_NOVELTIES } from 'shared/consts'
import {
  SERP_PRODUCT_DETAIL_CLICK,
  type TrackingEventName,
} from 'shared/experiments/consts'
import { event as e } from 'shared/experiments/utils/event'
import {
  bindDynamicEventsTracking,
  bindTrackingEvents,
} from 'shared/experiments/utils/tracking'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'

const INDEX_EVENT = [
  { regexp: /novelties/i, event: e`CLICK ON NOVELTIES SORTING` },
  { regexp: /topsellerCount/, event: e`CLICK ON TOPSELLER SORTING` },
  { regexp: /productName.+asc$/, event: e`CLICK ON A-Z SORTING` },
  { regexp: /productName.+desc$/, event: e`CLICK ON Z-A SORTING` },
  { regexp: /price.+asc$/i, event: e`CLICK ON PRICE ASC SORTING` },
  { regexp: /price.+desc/i, event: e`CLICK ON PRICE DESC SORTING` },
]

const convertIndexToEvent = (index: string): TrackingEventName | null => {
  const eventDef = INDEX_EVENT.find(def => index.match(def.regexp))

  return eventDef?.event || null
}

const handleSearchResults = () => {
  bindDynamicEventsTracking([
    {
      parentElementSelector:
        '[data-clientside-hook~="algoliaSearchListContainer"]',
      selector: '[data-clientside-hook~="productDetailButton"]',
      trackingEvent: SERP_PRODUCT_DETAIL_CLICK,
    },
  ])
}

const handleSortByOptionChange = () => {
  bindTrackingEvents([
    {
      event: 'click',
      selector:
        '.o-SortByDesktop--select [role=option], .o-SortByMobile button',
      handler: event => {
        const { value, qaId } = (<HTMLElement>event.currentTarget).dataset
        const index = value || qaId

        if (index) {
          const trackingEvent = convertIndexToEvent(index)
          trackingEvent && trackOptimizelyEvent(trackingEvent)
        }
      },
    },
  ])
}

export default () => {
  const publicRuntimeConfig = store.getState()?.publicRuntimeConfig
  const { ruleContexts } = publicRuntimeConfig?.pageProperties || {}

  if (ruleContexts?.includes(CTX_RULE_PRODUCTS_NOVELTIES)) {
    handleSearchResults()
    handleSortByOptionChange()
  }
}
